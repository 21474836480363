import React from 'react'

function Footer() {
  return (
    <div className="footer">
      <div>
        <img className="footer-img" src="/assets/images/logo-dark.svg" alt="logo-img" />
      </div>
      <div className="footer-links">
        <span><a href='/privacy-policy'>Privacy Policy</a></span> | <span><a href='/term-and-conditions'>Terms and Conditions</a></span>
      </div>
    </div>
  );
}

export default Footer;
