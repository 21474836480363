
import authAxios from "../services/authAxios";
import UnauthAxios from "../services/unauthAxios";
import ROOT_URL from "./api-url";

const apiFunc = {
    postUpload: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/media/upload`,
      data: data,
    }).catch((err) => {
      console.log(err);
    }),
    
};

export default apiFunc;
